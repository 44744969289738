<template>
  <main style="padding-top: 90px">
    <section class="information">
      <div class="container--xl">
        <div class="information__inner">
          <h1 class="title fs64 tc pb50 m0a">
            {{ $t('usefulInformation.head.title') }}
          </h1>
          <div class="information__container">
            <div class="information__header">
              <div class="information__tab-list">
                <carousel
                  :breakpoints="breakpoints"
                  ref="carousel"
                  :snapAlign="start"
                  :itemsToShow="5"
                >
                  <slide
                    class="slider__item"
                    v-for="tab in tabs"
                    :key="tab.id"
                    :class="{
                      'carousel__slide--visible': tab.id === activeTab,
                    }"
                  >
                    <div class="information__tab-item">
                      <a
                        :href="`#${activeTab}`"
                        class="information__tab-link"
                        :class="{
                          active: tab.id === currentHash,
                        }"
                        @click="selectTab(tab.id)"
                      >
                        {{ $t(tab.name) }}
                      </a>
                    </div>
                  </slide>
                  <template #addons>
                    <navigation />
                  </template>
                </carousel>
              </div>
            </div>
            <div class="information__content">
              <FinancialMediator
                v-if="currentHash === '1'"
                :title="$t('usefulInformation.financialMediator.title')"
              />
              <Announcements
                v-if="currentHash === '2'"
                :title="$t('usefulInformation.announcements.title')"
                :newsTitle="$t('announcements.id1.title')"
                :body1="$t('announcements.id1.body.p1')"
                :body2="$t('announcements.id1.body.p2')"
                :body3="$t('announcements.id1.body.p3')"
                :date="$t('announcements.id1.date')"
                :linkText="$t('announcements.id1.body.linkText')"
                :href="$t('announcements.id1.body.link')"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import PrivacyPolicy from '@/components/UsefulInformationTabs/PrivacyPolicy.vue'
import CustomerRights from '@/components/UsefulInformationTabs/CustomerRights.vue'
import FAQ from '@/components/UsefulInformationTabs/FAQ.vue'
import FinancialMediator from '@/components/UsefulInformationTabs/FinancialMediator.vue'
import Announcements from '@/components/UsefulInformationTabs/Announcements.vue'
export default {
  name: 'Useful Information',
  components: {
    Carousel,
    Slide,
    Navigation,
    PrivacyPolicy,
    CustomerRights,
    FAQ,
    FinancialMediator,
    Announcements,
  },
  metaInfo() {
    return {
      title: this.$t('meta.pages.usefulInformation.title') + ' | Team Pay',
    }
  },
  data() {
    return {
      tabs: [
        {
          id: '1',
          name: 'usefulInformation.tabs.tab2',
        },

        {
          id: '2',
          name: 'usefulInformation.tabs.tab3',
        },
      ],
      activeTab: '1',
      breakpoints: {
        320: {
          itemsToShow: 1.5,
        },
        550: {
          itemsToShow: 3,
        },
        1080: {
          itemsToShow: 5,
        },
      },
      currentHash: window.location.hash.charAt(1),
    }
  },
  methods: {
    selectTab(id) {
      this.activeTab = id
      this.currentHash = id
      this.$refs.carousel.slideTo(this.currentHash - 1)
    },
    mountSlide() {
      this.$refs.carousel.slideTo(this.currentHash - 1)
    },
  },
  mounted() {
    this.$route.path = this.$route.path + window.location.hash
    this.activeTab = window.location.hash.charAt(1)
    this.mountSlide()
  },
  updated() {
    this.$route.path = this.$route.path + window.location.hash
    this.currentHash = window.location.hash.charAt(1)
    this.mountSlide()
  },
}
</script>
<style></style>
