<template>
  <div class="information-static">
    <div class="container">
      <div class="information__inner">
        <div class="title fs24 pb45">{{ title }}</div>
        <article class="article"></article>
      </div>
    </div>
    <p class="updated">{{ $t('updated') }} 28.02.2022</p>
  </div>
</template>

<script>
export default {
  name: 'Customer Rights',
  props: {
    title: String,
  },
}
</script>

<style></style>
